import type { WidgetConfig, WidgetType } from './types/config';
import type { FloatingButton } from './types/venue';

(() => {
  const reloadVenueData = () => {
    // @ts-expect-error
    if (window?.performance?.getEntriesByType?.('navigation')?.[0]?.type === 'back_forward') {
      window.location.reload();
    }
  };

  // Load the widget script, styles, and Stripe script once, if they are not already initialized.
  // The `window.umaiWidget` serves as an indicator that scripts and styles have been loaded.
  if (window.umaiWidget) {
    floatingButtonConfig();
    return;
  }

  const umaiWidget: WidgetConfig = {};

  function loadScript() {
    const widgetScript = document.createElement('script');
    widgetScript.async = true;
    widgetScript.type = 'module';
    widgetScript.src = `${import.meta.env.VITE_WIDGET_URL}?v=${import.meta.env.VITE_WIDGET_VERSION}`;

    const entry = document.getElementsByTagName('script')[0];
    if (entry.parentNode) {
      entry.parentNode.insertBefore(widgetScript, entry);
    }
  }

  function loadStripeScript() {
    const stripeScript = document.createElement('script');
    stripeScript.async = true;
    stripeScript.id = 'stripe-js';
    stripeScript.src = 'https://js.stripe.com/v3/';

    const entry = document.getElementsByTagName('script')[0];
    if (entry.parentNode) {
      entry.parentNode.insertBefore(stripeScript, entry);
    }
  }

  function loadStyle() {
    const head = document.getElementsByTagName('head')[0];
    const widgetLink = document.createElement('link');
    widgetLink.rel = 'stylesheet';
    widgetLink.type = 'text/css';
    widgetLink.href = `${import.meta.env.VITE_WIDGETCSS_URL}?v=${import.meta.env.VITE_WIDGET_VERSION}`;
    widgetLink.media = 'all';
    head.appendChild(widgetLink);
  }

  function onModalReady() {
    if (typeof umaiWidget.openModal === 'function') {
      umaiWidget.openModal();
    } else {
      setTimeout(() => {
        onModalReady();
      }, 50);
    }
  }

  // Scripts and styles are loaded only when bundling in these specific Vite modes: 'dev', 'staging', or 'prod'.
  if (['dev', 'staging', 'prod'].includes(import.meta.env.MODE)) {
    loadStyle();
    loadScript();
  }

  // TODO: This should be loaded for venues that don't use payments
  loadStripeScript();

  umaiWidget.openWidget = () => {
    // this re-load widget with correct venue Data when a user navigate using browser back forward button
    reloadVenueData();
    if (umaiWidget.validConfig) {
      onModalReady();
    }
  };

  function reOpenWidget() {
    // This is to reopen the widget after payment redirect
    const url = new URL(window.location.href);
    const paymentIntent = url.searchParams.get('payment_intent');
    const apiKey = url.searchParams.get('api_key');
    const widgetType = url.searchParams.get('widget_type');

    if (paymentIntent) {
      umaiWidget.config!({
        apiKey,
        widgetType,
      } as WidgetConfig);
      umaiWidget.openWidget!();
    }
  }

  /**
   * This script uses data attributes to set `apiKey` and `widgetType` for the initial configuration of the widget
   * and floating button, with the modal initially closed on first root.render.
   *
   * Example of required data attributes:
   * <script src="http://localhost:8080/dist/embed.min.js" data-api-key="66ec67a8-6fce-41b0-8352-4d2eb02876f5" data-widget-type="reservation"></script>
   *
   * NOTE: For the floating button, configuration values not set in `umaiWidget` will be fetched dynamically from the API if not provided.
   */
  function floatingButtonConfig() {
    if (document.currentScript) {
      const apiKey = document.currentScript.getAttribute('data-api-key');
      const widgetType = document.currentScript.getAttribute('data-widget-type') as WidgetType;
      const floatingButtonIsEnabled = document.currentScript.getAttribute(
        'data-floating-button-is-enabled'
      );
      const floatingButtonLanguage = document.currentScript.getAttribute(
        'data-floating-button-language'
      );
      const floatingButtonLocation = document.currentScript.getAttribute(
        'data-floating-button-location'
      );
      const floatingButtonRestaurantId = document.currentScript.getAttribute(
        'data-floating-button-restaurant-id'
      );
      const floatingButtonRestaurantName = document.currentScript.getAttribute(
        'data-floating-button-restaurant-name'
      );

      if (apiKey) {
        // Use `window.umaiWidget` to call `umaiWidget.config`, as this is triggered when `floatingButtonConfig` function is invoked,
        // especially if the embed script is loaded multiple times.
        window.umaiWidget.config!({
          apiKey,
          widgetType,
          floatingButton: {
            ...(typeof floatingButtonIsEnabled === 'string' && {
              isEnabled: floatingButtonIsEnabled === 'true',
            }),
            ...(typeof floatingButtonLanguage === 'string' && {
              language: floatingButtonLanguage as FloatingButton['language'],
            }),
            ...(typeof floatingButtonLocation === 'string' && {
              location: floatingButtonLocation as FloatingButton['location'],
            }),
            ...(typeof floatingButtonRestaurantId === 'string' && {
              restaurantId: +floatingButtonRestaurantId,
            }),
            ...(typeof floatingButtonRestaurantName === 'string' && {
              restaurantName: floatingButtonRestaurantName as FloatingButton['restaurantName'],
            }),
          },
        });
      }
    }
  }

  const checkAnchor = (anchor?: string) =>
    anchor && window.location?.hash?.toLowerCase()?.includes(anchor.toLowerCase());

  function getWidgetType(config: WidgetConfig = {}): WidgetType {
    if (config.parseAnchors) {
      if (checkAnchor(config.reservationAnchor)) {
        return 'reservation';
      }
      if (checkAnchor(config.deliveryAnchor)) {
        return 'delivery';
      }
      if (checkAnchor(config.takeawayAnchor)) {
        return 'takeaway';
      }
      if (checkAnchor(config.giftcardAnchor) || checkAnchor('giftcard')) {
        return 'giftcard';
      }
    }
    return (config.widgetType?.toLowerCase() as WidgetType) || 'reservation';
  }

  umaiWidget.config = (venue) => {
    umaiWidget.validConfig = false;
    umaiWidget.apiKey = null;
    umaiWidget.isRailsPage = false;
    umaiWidget.isBookingPlatform = false;
    umaiWidget.isLandingPage = false;

    const hostname = window.location.host.replace('www.', '');

    if (hostname === import.meta.env.VITE_LANDING_PAGE_URL) {
      umaiWidget.isLandingPage = true;
    }

    if (
      hostname === import.meta.env.VITE_BOOKING_PLATFORM_DOMAIN ||
      // booking platform DEV env url are dynamic like booking-platform-csubwglbq-umais-projects.vercel.app
      // so to match with url we are checking static prefix and suffix of booking platform url
      (hostname.startsWith('booking-platform-') && hostname.endsWith('-umais-projects.vercel.app'))
    ) {
      umaiWidget.isBookingPlatform = true;
    }

    if (venue !== null && typeof venue === 'object') {
      if (venue.apiKey) {
        umaiWidget.validConfig = true;
        umaiWidget.apiKey = venue.apiKey;
        umaiWidget.parseAnchors = venue.parseAnchors;
        umaiWidget.reservationAnchor = venue.reservationAnchor;
        umaiWidget.giftcardAnchor = venue.giftcardAnchor;
        umaiWidget.deliveryAnchor = venue.deliveryAnchor;
        umaiWidget.takeawayAnchor = venue.takeawayAnchor;
        umaiWidget.widgetType = getWidgetType(venue);
        // Fallback to values set before to retain the `floatingButton`, if override using data attr on script or directly calling `umaiWidget.config`
        umaiWidget.floatingButton = venue.floatingButton ?? umaiWidget.floatingButton;
      } else {
        console.error('umaiWidget: apiKey not present!');
      }
    }

    // TODO: Remove the below code block for isRailsPage once the rails pages is fully migrated to the landing page domain
    const { pathname } = window.location;
    // y - confirmation
    // c - cancel
    // e - edit or cancel
    // f - confirm or edit or cancel
    // g - confirm or late or edit or cancel
    // p - manual payment
    umaiWidget.isRailsPage =
      hostname === import.meta.env.VITE_UMAI_DOMAIN &&
      (pathname.startsWith('/y/') ||
        pathname.startsWith('/c/') ||
        pathname.startsWith('/e/') ||
        pathname.startsWith('/f/') ||
        pathname.startsWith('/g/') ||
        pathname.startsWith('/p/'));
  };

  window.umaiWidget = umaiWidget;

  floatingButtonConfig();
  reOpenWidget();
})();
